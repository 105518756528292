var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "fullscreen": ""
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.listeners.onAddEdit();
      }]
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-column"
  }, [_c('v-card-title', {
    staticClass: "px-3"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.add.PositionTransaction.title")) + " ")]), _c('span', {
    staticClass: "ml-auto text-subtitle-1"
  }, [_vm._v(" " + _vm._s("".concat(_vm.$t('planning.workforcePlanning.form.remainingScope.title'), ":")) + " ")]), _c('span', {
    class: "ml-1 text-subtitle-1 font-weight-bold ".concat(Number(_vm.state.remainingScope) >= 0 ? 'primary--text' : 'red--text')
  }, [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(_vm.state.remainingScope, 2)) + " ")])]), _c('v-card-text', {
    staticClass: "mt-4 d-flex flex-column justify-space-between"
  }, [_c('v-form', {
    ref: "formElement",
    model: {
      value: _vm.state.isTransactionFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTransactionFormValid", $$v);
      },
      expression: "state.isTransactionFormValid"
    }
  }, [_c('v-row', {
    staticClass: "d-flex align-center"
  }, [_c('v-col', [_c('CommonAutocomplete', {
    staticClass: "mr-4",
    attrs: {
      "items": _vm.state.filteredPositions,
      "item-text": "name",
      "item-value": "id",
      "loading": _vm.state.isLoadingPositionsBasic,
      "label": _vm.$t("planning.workforcePlanning.form.inputPosition.position"),
      "disabled": _vm.state.isInputPositionFormOpen
    },
    on: {
      "change": _vm.listeners.onAddPositionTransactionInput
    },
    model: {
      value: _vm.state.selectedInput,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "selectedInput", $$v);
      },
      expression: "state.selectedInput"
    }
  })], 1), _c('v-divider', {
    staticClass: "mx-4",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "justify-center d-flex"
  }, [_c('v-tooltip', {
    staticClass: "mx-auto",
    attrs: {
      "top": "",
      "open-delay": "200"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.listeners.onCreateTransactionOutput
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")])], 1)];
      }
    }])
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.add')) + " ")])], 1)], 1), _c('v-row', [!_vm.state.isInputPositionFormOpen ? _c('v-col', _vm._l(_vm.state.transactionInputDetails, function (inputPosition, index) {
    return _c('div', {
      key: inputPosition.id,
      staticClass: "mb-6"
    }, [_c('v-row', {
      staticClass: "rounded-lg grey lighten-3 pa-2 d-flex align-center"
    }, [_c('v-col', {
      staticClass: "py-1 d-flex flex-column",
      attrs: {
        "cols": "4"
      }
    }, [_c('div', {
      staticClass: "pb-4 d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.profile')) + " ")]), _c('span', [_vm._v(" " + _vm._s(inputPosition.profile.name) + " ")])]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.professionalUnit')) + " ")]), _c('span', [_vm._v(" " + _vm._s(inputPosition.professionalUnit.name) + " ")])])]), _c('v-col', {
      staticClass: "py-1 d-flex flex-column",
      attrs: {
        "cols": "2"
      }
    }, [_c('div', {
      staticClass: "pb-4 d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.start')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(inputPosition.start)) + " ")])]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.end')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(inputPosition.end)) + " ")])])]), _c('v-col', {
      staticClass: "py-1 d-flex flex-column",
      attrs: {
        "cols": "3"
      }
    }, [_c('div', {
      staticClass: "pb-4 d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.availableScopeForTransaction')) + " ")]), _c('v-menu', {
      attrs: {
        "open-on-hover": "",
        "offset-y": "",
        "close-delay": "100",
        "min-width": "auto",
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
              attrs = _ref2.attrs;
          return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(_vm.functions.getFormattedScope(inputPosition, index)) + " ")])];
        }
      }], null, true)
    }, [_c('v-card', {
      staticClass: "pa-1"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.functions.getFullScope(inputPosition, index)) + " ")])])], 1)], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.hourlyRate')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.convertToEuro(inputPosition.hourlyRate)) + " ")])])]), _c('v-col', {
      staticClass: "mt-2 mb-auto d-flex"
    }, [_c('CommonNumberInput', {
      staticClass: "required text-body-2",
      attrs: {
        "label": _vm.$t("planning.workforcePlanning.form.inputPosition.scope"),
        "rules": [_vm.functions.formRules.isRequired(_vm.state.transactionInput[index].scope, _vm.$t('planning.workforcePlanning.form.inputPosition.scope')), function (value) {
          return _vm.functions.formRules.isInAvailableScope(value, inputPosition, index);
        }],
        "dense": ""
      },
      model: {
        value: _vm.state.transactionInput[index].scope,
        callback: function callback($$v) {
          _vm.$set(_vm.state.transactionInput[index], "scope", $$v);
        },
        expression: "state.transactionInput[index].scope"
      }
    })], 1), _c('div', {
      staticClass: "mb-auto ml-auto d-flex align-end"
    }, [_c('v-btn', {
      staticClass: "ml-4",
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onOpenInputPositionDetails(inputPosition);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiOpenInApp) + " ")])], 1), _c('v-btn', {
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onDeletePositionTransactionInput(index);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)], 1)], 1)], 1);
  }), 0) : _c('v-col', {
    staticClass: "py-4 rounded-lg grey lighten-3 d-flex flex-column align-center"
  }, [_c('PositionForm', {
    attrs: {
      "is-editable": false
    },
    on: {
      "setFormfields": function setFormfields($event) {
        _vm.state.formFields = $event;
      }
    },
    model: {
      value: _vm.state.activeInputPosition,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "activeInputPosition", $$v);
      },
      expression: "state.activeInputPosition"
    }
  }), _c('div', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isInputPositionFormOpen = false;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiCloseCircleOutline) + " ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mx-4",
    attrs: {
      "vertical": ""
    }
  }), _vm.state.transactionOutput.length && !_vm.state.isOutputPositionFormOpen ? _c('v-col', _vm._l(_vm.state.transactionOutput, function (outputPosition, index) {
    return _c('div', {
      key: outputPosition.id,
      staticClass: "mb-6"
    }, [_c('v-row', {
      staticClass: "rounded-lg grey lighten-3 pa-2"
    }, [_c('v-col', {
      staticClass: "py-1 d-flex flex-column",
      attrs: {
        "cols": "4"
      }
    }, [_c('div', {
      staticClass: "pb-4 d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.profile')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.getNameOfOutputProfile(outputPosition.profile)) + " ")])]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.professionalUnit')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.getNameOfOutputProfUnit(outputPosition.professionalUnit)) + " ")])])]), _c('v-col', {
      staticClass: "py-1 d-flex flex-column"
    }, [_c('div', {
      staticClass: "pb-4 d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.start')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(outputPosition.start)) + " ")])]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.end')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(outputPosition.end)) + " ")])])]), _c('v-col', {
      staticClass: "py-1 d-flex flex-column"
    }, [_c('div', {
      staticClass: "pb-4 d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.scope')) + " ")]), _c('span', [_vm._v(" " + _vm._s(outputPosition.scope) + " ")])]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.form.hourlyRate')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.functions.convertToEuro(outputPosition.hourlyRate)) + " ")])])]), _c('div', {
      staticClass: "mb-auto ml-auto d-flex align-end"
    }, [_c('v-btn', {
      staticClass: "ml-4",
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onAdjustTransactionOutput(outputPosition);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiOpenInApp) + " ")])], 1), _c('v-btn', {
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onDeletePositionTransactionOutput(index);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)], 1)], 1)], 1);
  }), 0) : _c('v-col', {
    staticClass: "py-4 rounded-lg grey lighten-3 d-flex flex-column align-center"
  }, [_c('PositionForm', {
    attrs: {
      "is-edit-mode": _vm.transactionToEdit && true,
      "selected-input-positions": _vm.state.transactionInput
    },
    on: {
      "setFormfields": function setFormfields($event) {
        _vm.state.formFields = $event;
      },
      "validationChange": function validationChange($event) {
        _vm.state.isTransactionOutputFormValid = $event;
      }
    },
    model: {
      value: _vm.state.activeOutputPosition,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "activeOutputPosition", $$v);
      },
      expression: "state.activeOutputPosition"
    }
  }), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_vm.state.transactionOutput.length ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isOutputPositionFormOpen = false;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiCloseCircleOutline) + " ")])], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "primary",
      "icon": "",
      "disabled": !_vm.state.isTransactionOutputFormValid
    },
    on: {
      "click": _vm.listeners.onAddEditTransactionOutput
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiCheckCircleOutline) + " ")])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('planning.workforcePlanning.form.description')
    },
    model: {
      value: _vm.state.transactionForm.description,
      callback: function callback($$v) {
        _vm.$set(_vm.state.transactionForm, "description", $$v);
      },
      expression: "state.transactionForm.description"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "mt-auto"
  }, [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.cancel')) + " ")]), _c('v-spacer'), _vm.transactionToEdit ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "outlined": "",
      "elevation": "0",
      "loading": _vm.state.isLoadingPositionTransactions,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onDelete();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("form.delete")) + " ")]) : _vm._e(), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "disabled": !_vm.state.transactionOutput.length || !_vm.state.transactionInput.length || !_vm.state.isTransactionFormValid || _vm.state.remainingScope < 0,
      "elevation": "0",
      "loading": _vm.state.isLoadingPositionTransactions,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onAddEdit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("form.save")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }