
import { computed, defineComponent, nextTick, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep, round } from 'lodash-es'
import { mdiDelete, mdiPlus, mdiOpenInApp, mdiCheckCircleOutline, mdiCloseCircleOutline } from '@mdi/js'

import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'
import PositionForm from '@/views/planning/views/workforcePlanning/components/PositionForm.vue'

import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'
import { isOfType } from '@/utils/types/isOfType'
import { isPercentageRange, isRequired } from '@/utils/validation'
import {
  dateDashNotationToDot,
  dateDashNotationToDotWithForm,
  dateDotNotationToDashWithForm,
} from '@/utils/convertDate'
import {
  convertCommaIntoDotInput,
  convertCommaIntoDotInputWithForm,
  convertDotIntoCommaInput,
  convertDotIntoCommaInputWithForm,
} from '@/utils/convertInput'
import { convertToEuro } from '@/utils/convertCurrency'
import { FormField } from '@/utils/types/formField'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetProfilesBasic } from '@/api/profile'
import {
  NewTransactionInput,
  PositionTransactionInput,
  PositionTransactionOutput,
} from '@/api/types/positionTransaction'
import { useGetPosition, useGetPositions, useGetPositionsBasic } from '@/api/position'
import {
  useCreatePositionTransaction,
  useDeletePositionTransaction,
  useGetPositionTransaction,
  useUpdatePositionTransaction,
} from '@/api/positionTransaction'
import { PositionId, PositionInput, PositionOutput } from '@/api/types/position'
import { PROFILE_TYPE } from '@/api/types/profile'

export default defineComponent({
  name: 'AddEditPositionTransactionDialog',
  components: {
    CommonNumberInput,
    CommonAutocomplete,
    PositionForm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    transactionToEdit: {
      type: Object as PropType<PositionOutput['sourceTransaction']>,
      default: null,
    },
    transactionInputPosition: {
      type: Object as PropType<PositionOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    // Form elements

    const formElement = ref<HTMLFormElement | null>(null)

    const transactionForm = ref<PositionTransactionInput>({} as PositionTransactionInput)

    const isTransactionFormValid = ref<boolean>(true)

    const formFields = ref<FormField[]>([] as FormField[])

    //transaction input

    const transactionInputDetails = ref<PositionOutput[]>([])

    const transactionInput = ref<NewTransactionInput[]>([])

    const isInputPositionFormOpen = ref<boolean>(false)

    const activeInputPosition = ref<PositionOutput>({} as PositionOutput)

    const selectedInput = ref<null>(null)

    watch(
      () => props.transactionInputPosition,
      () => {
        if (props.transactionInputPosition) {
          transactionInputDetails.value.push(props.transactionInputPosition)

          transactionInput.value.push({ position: props.transactionInputPosition.id } as NewTransactionInput)
        }
      },
      { immediate: true }
    )

    function resetInputSelect(): void {
      nextTick(() => (selectedInput.value = null))
    }

    async function onAddPositionTransactionInput(positionId: PositionId): Promise<void> {
      await getPosition(positionId)
      // add details for inputPosition
      if (!position.value) return
      transactionInputDetails.value.push(position.value)

      // add inputPosition card
      transactionInput.value.push({ position: positionId } as NewTransactionInput)

      resetInputSelect()
    }

    function onDeletePositionTransactionInput(index: number): void {
      transactionInput.value.splice(index, 1)
      transactionInputDetails.value.splice(index, 1)
    }

    function onOpenInputPositionDetails(position: PositionOutput): void {
      isInputPositionFormOpen.value = true

      let formattedPosition = cloneDeep(position)
      formattedPosition = dateDashNotationToDotWithForm(formFields.value, formattedPosition)
      formattedPosition = convertDotIntoCommaInputWithForm(formFields.value, formattedPosition)
      activeInputPosition.value = formattedPosition
    }

    const {
      exec: getPositionsBasic,
      data: positionsBasic,
      isLoading: isLoadingPositionsBasic,
    } = useGetPositionsBasic()
    getPositionsBasic({ params: { hasRemainingScopeForTransaction: true, limited: false } })

    const filteredPositions = computed(() =>
      // filter positions that not been selected
      (positionsBasic.value ?? []).filter(
        (position) => !transactionInput.value.some((input) => input.position === position.id)
      )
    )

    const { getPosition, data: position } = useGetPosition()
    const { exec: getPositions, data: positions } = useGetPositions()

    watch(
      () => props.transactionToEdit,
      () => {
        if (props.transactionToEdit) {
          getPositions({ params: { allTransactionIds: props.transactionToEdit?.id, size: 9999 } })
        }
      },
      { immediate: true }
    )

    const { getPositionTransaction, data: positionTransaction } = useGetPositionTransaction()

    // transaction output

    const transactionOutput = ref<PositionInput[]>([])

    const isTransactionOutputFormValid = ref(true)

    const isOutputPositionFormOpen = ref<boolean>(false)

    const activeOutputPosition = ref<PositionInput>({} as PositionInput)

    const isOutputEditMode = ref<boolean>(false)

    function onCreateTransactionOutput(): void {
      isOutputEditMode.value = false

      activeOutputPosition.value = {} as PositionInput

      isOutputPositionFormOpen.value = true
    }

    function onAdjustTransactionOutput(position: PositionInput): void {
      isTransactionOutputFormValid.value = true

      isOutputEditMode.value = true

      activeOutputPosition.value = position

      isOutputPositionFormOpen.value = true
    }

    function onAddEditTransactionOutput(): void {
      if (!isOutputEditMode.value) {
        transactionOutput.value.push(activeOutputPosition.value)
      }

      isOutputPositionFormOpen.value = false
    }

    function onDeletePositionTransactionOutput(index: number): void {
      transactionOutput.value.splice(index, 1)
      activeOutputPosition.value = {} as PositionInput
    }

    const { exec: getProfUnitsBasic, data: profUnitsBasic } = useGetProfUnitsBasic()
    getProfUnitsBasic()

    const { exec: getProfilesBasic, data: profilesBasic } = useGetProfilesBasic()
    getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })

    // Transaction edit mode

    watch(
      () => positions.value,
      async () => {
        if (positions.value && props.transactionToEdit && !props.transactionInputPosition) {
          await getPositionTransaction(props.transactionToEdit.id)

          // prefill transaction input
          if (!transactionInput.value.length && !transactionInputDetails.value.length) {
            positions.value
              .filter((position) =>
                position.targetTransactions.some(
                  (transaction) => transaction.transactionId === props.transactionToEdit?.id
                )
              )
              .map((filteredPosition) => {
                // find scope of input position

                const inputPositionScope = positionTransaction.value?.input.find(
                  (input) => input.position.id === filteredPosition.id
                )?.scope

                if (!inputPositionScope) return

                let transactionInputPrefill = {
                  position: filteredPosition.id,
                  scope: inputPositionScope,
                }

                transactionInputPrefill = convertDotIntoCommaInputWithForm(formFields.value, transactionInputPrefill)

                transactionInput.value.push(transactionInputPrefill)
                transactionInputDetails.value.push(filteredPosition)
              })
          }

          // prefill transaction output

          if (!transactionOutput.value.length) {
            positions.value
              .filter((position) => position.sourceTransaction?.id === props.transactionToEdit?.id)
              .map((filteredPosition) => {
                let updatedFilteredPosition: PositionInput | PositionOutput = cloneDeep(filteredPosition)

                updatedFilteredPosition = mapBasicEntityToIdWithForm(updatedFilteredPosition)

                updatedFilteredPosition = dateDashNotationToDotWithForm(formFields.value, updatedFilteredPosition)

                updatedFilteredPosition = convertDotIntoCommaInputWithForm(formFields.value, updatedFilteredPosition)

                if (isOfType<PositionInput>(updatedFilteredPosition, 'id')) {
                  transactionOutput.value.push({ ...updatedFilteredPosition, commitMessage: '' } as PositionInput)
                } else return
              })
          }
        }
      },
      {
        immediate: true,
      }
    )

    // Api calls

    const { createPositionTransaction, isLoading: isLoadingCreatePositionTransaction } =
      useCreatePositionTransaction()

    const { updatePositionTransaction, isLoading: isLoadingUpdatePositionTransaction } =
      useUpdatePositionTransaction()

    const { deletePositionTransaction } = useDeletePositionTransaction()

    async function onAddEdit(): Promise<void> {
      if (!isOfType<PositionTransactionOutput>(transactionForm.value, 'id')) {
        const updatedNewPositionTransactionOutput: PositionInput[] = transactionOutput.value.map((output) => {
          let updatedOutput = output
          updatedOutput = convertCommaIntoDotInputWithForm(formFields.value, updatedOutput)
          updatedOutput = dateDotNotationToDashWithForm(formFields.value, updatedOutput)

          return updatedOutput
        })

        const updatedTransactionInput: NewTransactionInput[] = transactionInput.value.map((input) => {
          let updatedInput = input

          updatedInput = convertCommaIntoDotInputWithForm(formFields.value, updatedInput)

          return updatedInput
        })

        const updatedForm: PositionTransactionInput | PositionTransactionOutput = {
          ...transactionForm.value,
          input: updatedTransactionInput,
          output: updatedNewPositionTransactionOutput,
        }

        if (props.transactionToEdit) {
          updatedForm.id = props.transactionToEdit.id
        }

        try {
          if (props.transactionToEdit && isOfType<PositionTransactionOutput>(updatedForm, 'id')) {
            updatedForm.output.forEach((output) => {
              output.approvals = []
            })

            await updatePositionTransaction(updatedForm.id, updatedForm)
          } else {
            updatedForm.output.forEach((output) => {
              output.approvals =
                output.approvals?.map((approval) => {
                  const newApproval = {
                    approvedBy: Number(approval),
                    approvedOn: new Date().toISOString().split('T')[0],
                  }

                  return newApproval
                }) ?? []
            })

            await createPositionTransaction(updatedForm)
          }

          close()

          addNotification({
            text: root.$t('planning.workforcePlanning.transaction.create.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    async function onDelete(): Promise<void> {
      try {
        if (props.transactionToEdit) {
          await deletePositionTransaction(props.transactionToEdit.id)
        }

        close()

        addNotification({
          text: root.$t('planning.workforcePlanning.transaction.delete.message') as string,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const isLoadingPositionTransactions = computed(
      () => isLoadingCreatePositionTransaction.value || isLoadingUpdatePositionTransaction.value
    )

    const remainingScope = computed((): number => {
      const inputScope = transactionInput.value
        .map((input) => {
          return input.scope ? convertCommaIntoDotInput(String(input.scope)) : 0
        })
        .reduce((acc, num) => acc + num, 0)

      const outputScope = transactionOutput.value
        .map((output) => {
          return output.scope ? convertCommaIntoDotInput(String(output.scope)) : 0
        })
        .reduce((acc, num) => acc + num, 0)

      return round(inputScope - outputScope, 1)
    })

    function getNameOfOutputProfile(id: number): string {
      const profiles = computed(() => profilesBasic.value)

      const name = profiles.value?.find((entity) => entity.id === id)?.name

      return name ? name : '-'
    }

    function getNameOfOutputProfUnit(id: number): string {
      const profUnits = computed(() => profUnitsBasic.value)

      const name = profUnits.value?.find((entity) => entity.id === id)?.name

      return name ? name : '-'
    }

    function close(): void {
      emit('added-edited')

      emit('close')
    }
    function isInAvailableScope(usedScope: string, position: PositionOutput, index: number): string | boolean {
      if (!usedScope) {
        return true
      } else if (props.transactionToEdit && positionTransaction.value) {
        const usedAvailableScopeForTransaction = Number(transactionInputDetails.value[index].scope)

        const availableScopeIncludingUsedTransactionInputScope =
          usedAvailableScopeForTransaction + position.availableScopeForTransaction

        return convertCommaIntoDotInput(usedScope) > availableScopeIncludingUsedTransactionInputScope
          ? (root.$t('planning.workforcePlanning.form.error.formerAvailableScope') as string)
          : true
      } else {
        return convertCommaIntoDotInput(usedScope) > position.availableScopeForTransaction
          ? (root.$t('planning.workforcePlanning.form.error.availableScope') as string)
          : true
      }
    }

    function getFormattedScope(pos: PositionOutput, index: number): string {
      if (props.transactionToEdit && positionTransaction.value?.input) {
        const usedAvailableScopeForTransaction = positionTransaction.value.input[index]?.scope

        const availableScopeIncludingUsedTransactionInputScope =
          usedAvailableScopeForTransaction + pos.availableScopeForTransaction

        const availableScope = convertDotIntoCommaInput(round(availableScopeIncludingUsedTransactionInputScope, 2))

        return availableScope
      } else {
        const availableScope = convertDotIntoCommaInput(round(pos.availableScopeForTransaction, 2))
        return availableScope
      }
    }

    function getFullScope(pos: PositionOutput, index: number): string {
      if (props.transactionToEdit && positionTransaction.value?.input) {
        const usedAvailableScopeForTransaction = positionTransaction.value.input[index]?.scope

        const availableScopeIncludingUsedTransactionInputScope =
          usedAvailableScopeForTransaction + pos.availableScopeForTransaction

        const availableScope = convertDotIntoCommaInput(availableScopeIncludingUsedTransactionInputScope)

        return availableScope
      } else {
        const availableScope = convertDotIntoCommaInput(pos.availableScopeForTransaction)
        return availableScope
      }
    }

    watch(
      () => activeOutputPosition.value.ancestor,
      async () => {
        if (activeOutputPosition.value.ancestor) {
          await getPosition(activeOutputPosition.value.ancestor)

          if (!position.value) return

          activeOutputPosition.value = {
            ...activeOutputPosition.value,
            start: position.value.start,
            end: position.value.end,
            profile: position.value.profile.id,
            professionalUnit: position.value.professionalUnit.id,
            organizationalUnit: position.value.organizationalUnit.id,
            budgetSourceOrgUnit: position.value.budgetSourceOrgUnit.id,
            scope: position.value.availableScopeForTransaction,
            sourcingType: position.value.sourcingType,
            hourlyRate: Number(position.value.hourlyRate),
            dailyWorkingTime: position.value.dailyWorkingTime,
            positionStatus: position.value.positionStatus,
          }

          activeOutputPosition.value = dateDashNotationToDotWithForm(formFields.value, activeOutputPosition.value)
          activeOutputPosition.value = convertDotIntoCommaInputWithForm(formFields.value, activeOutputPosition.value)
        }
      },
      { immediate: true }
    )

    return reactive({
      formElement,
      icons: {
        mdiDelete,
        mdiPlus,
        mdiOpenInApp,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
      },

      state: {
        formFields,

        transactionForm,
        isTransactionFormValid,

        filteredPositions,
        isLoadingPositionsBasic,

        selectedInput,

        transactionInput,
        transactionInputDetails,
        isInputPositionFormOpen,
        activeInputPosition,

        isTransactionOutputFormValid,
        transactionOutput,
        activeOutputPosition,
        isOutputPositionFormOpen,

        remainingScope,

        isLoadingPositionTransactions,
      },

      listeners: {
        onAddPositionTransactionInput,
        onDeletePositionTransactionInput,

        onCreateTransactionOutput,
        onAdjustTransactionOutput,
        onAddEditTransactionOutput,
        onDeletePositionTransactionOutput,

        onAddEdit,
        onDelete,

        onOpenInputPositionDetails,
      },
      functions: {
        formRules: {
          isRequired,
          isPercentageRange,
          isInAvailableScope,
        },

        dateDashNotationToDot,
        convertDotIntoCommaInput,
        convertToEuro,
        round,

        getFormattedScope,
        getFullScope,

        getNameOfOutputProfUnit,
        getNameOfOutputProfile,
      },
    })
  },
})
